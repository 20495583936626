<template>

    <svg class="agoda-logo"
         width="101"
         height="40"
         viewBox="0 0 101 40"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8 15.1C13.4 13.3 13.5 11 13.4 8.89998C13.4 8.09998 13.3 7.19998 13 6.69998C12.7 6.19998 12.2 5.69998 11.7 5.29998C10.8 4.69998 9.3 4.69998 7.8 4.59998C6.9 4.59998 6 4.59998 5.2 4.69998C4.3 4.99998 3.4 5.39998 2.8 5.89998C2.2 6.39998 1.6 7.19998 1.6 8.09998C1.6 8.29998 1.6 8.59998 1.8 8.79998C2 9.19998 2.8 9.49998 3.5 9.29998C3.7 9.19998 3.8 9.09998 4 8.89998C4.6 8.29998 4.9 7.59998 5.7 7.19998C6.3 6.89998 7.5 6.69998 8.4 6.89998C8.8 6.99998 9.4 7.19998 9.6 7.49998C9.7 7.69998 9.8 7.99998 9.9 8.29998C10 8.59998 10.1 8.79998 10.1 9.19998C9.9 9.29998 9.7 9.39998 9.4 9.49998C8.7 9.69998 7.9 9.79998 7.1 9.99998C6.1 10.2 5 10.4 4 10.7C3.1 11 2.2 11.3 1.6 12C1.3 12.5 1 13.2 1 14.1C1 15 1.2 15.5 1.6 16.1C2.3 17.1 3.5 17.7 5.1 17.8C7.4 17.9 8.9 16.9 10.3 16.1C10.7 16.6 11.2 17.5 12 17.8C12.5 18 13 17.8 13.3 17.6C13.7 17.3 14 17.1 13.9 16.4C14 15.9 13.8 15.5 13.8 15.1ZM10.1 12.3C10 13.8 9.3 14.9 8 15.3C7.5 15.5 6.8 15.6 6.2 15.6C5.6 15.6 5.1 15.3 4.8 14.9C4.6 14.6 4.4 14.2 4.4 13.7C4.5 13.2 4.8 12.7 5.3 12.5C5.5 12.4 5.7 12.4 5.8 12.3C7 12 8.4 11.7 9.7 11.4C9.8 11.4 9.8 11.4 10 11.4C10.1 11.7 10.1 12 10.1 12.3Z" fill="currentColor"/>
        </g>
        <g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M36 9.60001C36 8.30001 36 7.00001 35.9 5.80001C35.9 5.50001 35.6 5.00001 35.4 4.90001C35 4.70001 34 4.60001 33.6 4.90001C33.2 5.10001 33 5.60001 32.9 6.00001C32.9 6.20001 32.9 6.40001 32.8 6.60001C32.6 6.50001 32.4 6.30001 32.2 6.10001C31.6 5.50001 31 5.10001 30 4.90001C29.3 4.70001 28.6 4.70001 27.9 4.70001C27.2 4.70001 26.6 4.90001 26.1 5.20001C25.1 5.70001 24.3 6.50001 23.8 7.50001C23.5 8.00001 23.3 8.50001 23.2 9.20001C23 9.80001 23 10.5 23 11.3C23 14.2 24.2 16.2 26.2 17.2C27.2 17.7 28.8 17.9 30.1 17.5C31.2 17.2 31.9 16.5 32.6 15.8C32.6 15.8 32.7 15.6 32.7 15.7C32.5 17.6 32.6 19.4 31.3 20.1C30.4 20.6 28.6 20.7 27.7 20.2C27.3 20 26.8 19.6 26.5 19.3C26.2 19 25.8 18.5 25.3 18.3C24.1 17.9 23 19.1 23.5 20.3C23.7 20.7 24.1 21.1 24.4 21.4C24.8 21.7 25.2 22 25.6 22.2C26.6 22.7 27.7 22.8 29 22.8C30.3 22.8 31.6 22.7 32.6 22.4C34.1 21.9 35.1 20.8 35.5 19.3C35.7 18.8 35.7 18.2 35.7 17.5C35.8 16.2 35.8 14.9 35.8 13.7C36 12.2 36 10.8 36 9.60001ZM32.5 12.7C32.3 13.3 31.9 14 31.4 14.4C30.9 14.9 30.1 15.2 29.4 15.2C28.6 15.2 27.8 14.8 27.3 14.3C26.4 13.4 26 11.5 26.4 9.80001C26.7 8.70001 27.2 7.90001 28.1 7.40001C28.4 7.30001 28.7 7.10001 29.1 7.10001C29.8 7.00001 30.6 7.40001 31 7.70001C31.6 8.00001 32.1 8.60001 32.3 9.00001C32.5 9.50001 32.6 10.2 32.7 10.7C32.7 11.4 32.7 12.1 32.5 12.7Z" fill="currentColor"/>
        </g>
        <g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M56.8 9.09997C56.4 7.89997 55.8 6.89997 54.9 6.09997C54.3 5.59997 53.6 5.19997 52.8 4.99997C52 4.79997 50.8 4.69997 49.8 4.79997C49.3 4.79997 48.8 4.89997 48.4 4.99997C47.2 5.39997 46.2 6.09997 45.5 6.99997C44.5 8.19997 43.9 9.89997 44.2 12.1C44.4 13.9 45.1 15.5 46.3 16.4C47.5 17.4 49.3 18 51.4 17.8C53.3 17.6 54.8 16.8 55.7 15.6C56.2 15 56.6 14.3 56.9 13.5C57 13.1 57.1 12.6 57.1 12.1C57.1 11.6 57.2 11.1 57.1 10.6C57 9.89997 56.9 9.49997 56.8 9.09997ZM53.2 13.7C52.9 14.2 52.4 14.7 51.9 15C51.7 15.1 51.5 15.2 51.3 15.2C50.4 15.4 49.5 15.2 48.8 14.8C48.2 14.4 47.8 13.8 47.6 13.1C47 11.2 47.4 8.79997 48.5 7.79997C48.7 7.59997 49 7.49997 49.3 7.29997C49.6 7.19997 49.9 7.09997 50.3 6.99997C51.4 6.89997 52.4 7.59997 52.9 8.19997C53.1 8.49997 53.3 8.89997 53.5 9.29997C53.6 9.69997 53.7 10.2 53.7 10.8C53.7 11.9 53.6 13 53.2 13.7Z" fill="currentColor"/>
        </g>
        <g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M77.9 13.3V11.5C77.9 8.19999 77.9 5.09999 77.9 1.79999C77.9 1.49999 78 0.899991 77.9 0.699991C77.9 0.599991 77.7 0.499991 77.5 0.399991C77.4 0.299991 77.2 0.199991 77.1 0.0999914C76.3 -0.200009 75.5 0.0999914 75.2 0.699991C74.9 1.29999 74.9 2.39999 74.9 3.39999C74.9 4.39999 74.9 5.49999 74.9 6.39999C74 5.79999 73.3 5.09999 72 4.89999C71.3 4.79999 70.6 4.79999 69.9 4.79999C69.2 4.79999 68.7 4.99999 68.2 5.29999C66.8 5.99999 65.9 7.39999 65.6 9.19999C65.3 11.2 65.5 13.5 66.2 14.8C66.4 15.3 66.8 15.7 67.1 16.1C67.8 16.8 68.7 17.5 70 17.6C70.7 17.6 71.4 17.7 72 17.5C72.6 17.3 73 17.1 73.5 16.8C73.7 16.7 73.9 16.5 74.1 16.3C74.3 16.1 74.5 15.9 74.7 15.8C74.8 16.5 75 17.1 75.5 17.4C75.9 17.6 76.3 17.6 76.8 17.5C77 17.4 77.2 17.4 77.3 17.3C77.6 17 77.7 16.2 77.7 15.6C77.9 14.7 77.9 14 77.9 13.3ZM74.5 12.4C74.3 13.6 73.4 14.7 72.3 15C71.2 15.3 70.2 14.8 69.6 14.2C69.4 14 69.2 13.8 69.1 13.5C68.5 12.5 68.5 10.7 68.9 9.39999C69.1 8.59999 69.7 7.79999 70.5 7.39999C70.8 7.29999 71.1 7.09999 71.5 7.09999C72 6.99999 72.6 7.29999 73 7.49999C73.5 7.79999 74 8.39999 74.3 8.89999C74.6 9.39999 74.7 10.2 74.7 10.9C74.6 11.4 74.6 11.9 74.5 12.4Z" fill="currentColor"/>
        </g>
        <g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M98.8 15.5C98.7 15.2 98.6 14.8 98.6 14.5C98.4 12.5 98.4 10.3 98.3 8.19998C98.3 7.99998 98.3 7.79998 98.3 7.59998C98.3 7.49998 98.2 7.39998 98.1 7.29998C97.9 6.89998 97.7 6.49998 97.5 6.29998C97.2 5.89998 96.8 5.49998 96.3 5.29998C95.4 4.79998 93.7 4.69998 92.3 4.79998C91.4 4.79998 90.7 4.79998 90.1 4.89998C89.6 4.99998 89 5.19998 88.5 5.49998C87.6 5.99998 86.6 6.89998 86.6 8.19998C86.6 8.69998 86.9 9.09998 87.4 9.29998C87.7 9.39998 88.3 9.49998 88.6 9.39998C89 9.29998 89.3 8.69998 89.5 8.39998C89.7 8.09998 90.1 7.69998 90.4 7.49998C90.9 7.09998 91.3 6.99998 91.9 6.99998C92.5 6.89998 93.1 6.89998 93.6 6.99998C94.4 7.19998 94.7 7.69998 94.9 8.39998C95 8.59998 95.1 8.89998 95 9.09998C94.9 9.29998 94.5 9.39998 94.1 9.49998C93 9.79998 91.9 9.99998 90.9 10.2C89.5 10.5 87.9 10.8 87.1 11.6C86.4 12.3 85.8 13.5 86.2 14.9C86.4 15.4 86.6 15.9 87 16.3C87.4 16.7 87.8 17.1 88.3 17.3C89.3 17.8 91.2 17.8 92.3 17.5C93.6 17.2 94.3 16.6 95.3 16C95.6 16.3 95.8 16.5 96 16.8C96.1 16.9 96.1 17 96.2 17.1C96.3 17.2 96.5 17.3 96.6 17.3C97 17.5 97.4 17.7 98 17.6C98.5 17.5 98.9 17 99.1 16.5C99.1 16.3 98.9 15.9 98.8 15.5ZM95 12.4C95 13.3 94.7 14 94.3 14.4C93.8 15 92.8 15.5 91.8 15.6C91.2 15.6 90.6 15.5 90.3 15.3C89.7 15 89.1 14 89.6 13.2C89.8 12.9 90.2 12.6 90.5 12.5C90.9 12.3 91.3 12.2 91.8 12.1C92.2 12 92.7 11.9 93.1 11.8C93.6 11.7 94 11.6 94.5 11.5C94.6 11.5 94.8 11.5 94.9 11.5C95.1 11.6 95 12.1 95 12.4Z" fill="currentColor"/>
        </g>
        <g>
            <path fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M94.4 26.9C91 26.9 88.3 29.7 88.3 33.2C88.3 36.7 91 39.5 94.4 39.5C97.8 39.5 100.5 36.7 100.5 33.2C100.5 29.7 97.8 26.9 94.4 26.9Z"
                  fill="#808080"
                  fill-opacity="0.6"/>
        </g>
        <g>
            <path d="M71.5 39.7C75.0899 39.7 78 36.7899 78 33.2C78 29.6102 75.0899 26.7 71.5 26.7C67.9101 26.7 65 29.6102 65 33.2C65 36.7899 67.9101 39.7 71.5 39.7Z" fill="#808080" fill-opacity="0.7"/>
        </g>
        <g>
            <path fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M50.5 26.7C46.9 26.7 44 29.6 44 33.2C44 36.8 46.9 39.7 50.5 39.7C54.1 39.7 57 36.8 57 33.2C57 29.6 54.1 26.7 50.5 26.7Z"
                  fill="#808080"
                  fill-opacity="0.8"/>
        </g>
        <g>
            <path d="M29.5 39.7C33.0899 39.7 36 36.7899 36 33.2C36 29.6102 33.0899 26.7 29.5 26.7C25.9101 26.7 23 29.6102 23 33.2C23 36.7899 25.9101 39.7 29.5 39.7Z" fill="#808080" fill-opacity="0.9"/>
        </g>
        <g>
            <path d="M6.5 39.7C10.0899 39.7 13 36.7899 13 33.2C13 29.6102 10.0899 26.7 6.5 26.7C2.91015 26.7 0 29.6102 0 33.2C0 36.7899 2.91015 39.7 6.5 39.7Z" fill="#808080"/>
        </g>
    </svg>

</template>
