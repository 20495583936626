<template>

    <svg class="lenovo-logo"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
         width="95"
         height="20"
         viewBox="0 0 95 20">
        <g>
            <path d="M39.9492 4c-1.7152 0-3.6661.7873-4.8582 2.3635l.001-.0029-.001.0011.001-2.1146H31V19h4.091v-8.3942c0-1.511 1.1828-3.1168 3.3731-3.1168 1.6931 0 3.4446 1.1657 3.4446 3.1168V19H46V9.8547C46 6.4623 43.5487 4 39.9492 4z" fill="currentColor"/>
        </g>
        <g>
            <path d="M75.2553 5L71.5 15.2215 67.7447 5H63l6.2245 15h4.551L80 5h-4.7447z" fill="currentColor"/>
        </g>
        <g>
            <path d="M18.9621 8.9646c.7109-.968 1.8437-1.5362 3.2116-1.5362 1.5048 0 2.6463.8714 3.2361 2.157l-7.2943 3.0688c-.1996-1.5744.1979-2.8063.8466-3.6896zm8.0214 6.0327c-1.7574 1.3185-2.7615 1.6337-4.3639 1.6337-1.4422 0-2.571-.4541-3.3672-1.2505l10.6742-4.4907c-.2328-1.6736-.8589-3.1678-1.8229-4.3044-1.4024-1.6524-3.4784-2.526-6.0053-2.526-4.5912 0-8.0532 3.4348-8.0532 7.9709 0 4.6536 3.4697 7.9698 8.5127 7.9698 2.8256 0 5.7145-1.3582 7.0656-2.9445l-2.64-2.0583z" fill="currentColor"/>
        </g>
        <g>
            <path d="M14 16.1183H4.3173V0H0v20h14v-3.8817z" fill="currentColor"/>
        </g>
        <g>
            <path d="M86.9726 7.6424c-2.2829 0-3.9396 1.7986-3.9396 4.358 0 2.4432 1.7552 4.3575 3.9949 4.3575 2.2829 0 3.9401-1.8483 3.9401-4.3575 0-2.4437-1.7553-4.358-3.9954-4.358zm0 12.3576C82.5021 20 79 16.5372 79 12.0004 79 7.5144 82.5263 4 87.0279 4 91.4978 4 95 7.463 95 12.0004 95 16.4854 91.4739 20 86.9726 20z" fill="currentColor"/>
        </g>
        <g>
            <path d="M55.9726 7.6424c-2.2829 0-3.9396 1.7986-3.9396 4.358 0 2.4432 1.7553 4.3575 3.9949 4.3575 2.2829 0 3.9402-1.8483 3.9402-4.3575 0-2.4437-1.7554-4.358-3.9955-4.358zm0 12.3576C51.5022 20 48 16.5372 48 12.0004 48 7.5144 51.5263 4 56.0279 4 60.4979 4 64 7.463 64 12.0004 64 16.4854 60.4739 20 55.9726 20z" fill="currentColor"/>
        </g>
    </svg>

</template>
