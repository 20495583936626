<template>

    <svg class="pwc-logo"
         width="49"
         height="19"
         viewBox="0 0 49 19"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.03044 11.5848C6.36113 11.6359 6.51385 11.6359 6.71769 11.6359C9.33859 11.6359 10.7383 10.0071 10.7383 6.95327C10.7383 4.35823 9.59354 2.98312 7.48068 2.98312C7.12474 2.98312 6.7688 3.00898 6.03044 3.11059V11.5848ZM0.0504967 17.4121L1.50135 17.2853L1.78093 16.9546V2.983H0V1.76184L4.70728 0.26048H6.03066V1.96567C8.37199 0.59118 9.28832 0.23462 10.4842 0.23462C13.2831 0.23462 15.4212 2.88139 15.4212 6.36751C15.4212 10.4399 12.4949 13.163 8.16815 13.163C7.65949 13.163 6.84477 13.1113 6.03066 13.0349V16.9546L6.36136 17.2853L8.0413 17.4121L7.99018 18.7608H0.0504967V17.4121Z" fill="currentColor"/>
        </g>
        <g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.1296 0.31098C21.3328 1.22731 21.4351 1.91456 21.4603 2.47433L21.7405 8.6836L25.4557 1.65961V0.92187L29.2984 0.61642L29.7818 10.2865L33.0647 5.73137L32.3522 1.04934C33.0389 0.43845 33.5229 0.23462 34.3117 0.23462C35.5581 0.23462 36.3981 1.02348 36.3981 2.22C36.3981 3.46641 35.7109 4.94251 34.0063 7.30909C33.4718 8.0474 31.8934 10.007 29.2984 13.1624H26.2957L25.7101 4.40799L20.951 13.1624H17.7193L17.9736 12.0176L17.5665 2.80503L15.0479 2.39797V1.38003L19.6535 0.31098H21.1296Z" fill="currentColor"/>
        </g>
        <g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M44.3263 1.60936C42.1635 1.93944 41.044 3.54302 41.044 6.31726C41.044 9.1161 42.5447 10.974 44.7844 10.974C45.8529 10.974 46.8209 10.6427 48.8051 9.6765V11.9409C46.388 13.0352 44.9883 13.3659 43.0294 13.3659C40.9165 13.3659 39.4404 12.8061 38.2192 11.5843C36.9975 10.3632 36.3613 8.7596 36.3613 6.95339C36.3613 2.95738 39.364 0.234859 43.7671 0.234859C46.6935 0.234859 48.7035 1.55825 48.7035 3.46665C48.7035 4.73893 47.7619 5.5789 46.3628 5.5789C45.6496 5.5789 45.0646 5.40093 44.3263 4.96801V1.60936Z" fill="currentColor"/>
        </g>
    </svg>

</template>
