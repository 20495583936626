<template>

    <svg class="disney-logo"
         width="75"
         height="30"
         viewBox="0 0 75 30"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <g>
            <path d="M68.7406 11.126C68.6739 13.4506 66.4056 14.8917 66.4056 17.2226C66.5056 17.3032 66.5723 17.4064 66.6724 17.3548C68.4738 15.4558 70.2752 12.7542 72.8771 11.9418C73.5443 11.903 74.2115 12.4834 74.445 12.9089C75.3457 14.6789 75.1455 17.1679 73.8779 18.7734C72.5435 20.3499 70.2752 21.9297 67.6732 21.6621C66.5723 24.2155 65.8718 26.9237 65.4048 29.7285C65.2046 30.3089 64.7042 29.793 64.3706 29.6963C62.1356 27.9553 63.9703 23.4417 64.1038 22.8646C64.2706 22.2876 64.871 20.927 65.3047 19.7857C64.2706 17.9706 64.9044 15.7751 65.7717 14.0921C66.4055 12.9863 67.3396 11.8643 68.4404 10.9713C68.5404 10.9713 68.6739 11.01 68.7406 11.126ZM73.2107 14.0148C72.8771 13.9599 72.7437 14.3855 72.4768 14.4661C71.1758 15.8589 69.9082 17.2484 69.2744 18.9088C70.1417 19.012 70.8756 18.4768 71.5761 18.1834C72.8771 17.4064 73.6777 16.1264 73.6111 14.6531C73.5443 14.4113 73.3108 14.2275 73.2107 14.0148Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M25.1174 6.3997C27.6693 8.4147 30.9818 11.8643 30.5682 15.8041C30.0712 18.9346 26.6385 21.2881 23.733 22.0361C20.9108 22.8356 17.3714 22.7582 14.4926 22.1167C14.2991 22.6228 14.1023 23.2387 13.4918 23.4514C13.1082 23.5836 12.6111 23.5062 12.3076 23.2676C11.4469 22.4906 12.1374 20.9915 10.8965 20.5111C8.458 19.5213 5.8326 17.5708 4.585 15.1593C4.4216 14.6789 4.625 14.1985 4.9186 13.8245C6.8267 12.3673 9.2319 11.7483 11.6404 11.3195C11.7772 11.3582 11.7238 11.1647 11.8339 11.126C11.9739 9.5366 12.0307 7.873 12.5544 6.4383C12.6745 6.2449 12.9681 6.1675 13.1649 6.3223C14.6827 7.4443 13.9655 9.5366 14.6293 11.0873C17.5349 11.2034 20.4404 11.3195 22.7389 12.9089C23.5128 13.5279 23.6996 14.7047 23.4561 15.3785C23.2126 16.0491 22.4053 16.523 21.6547 16.5778C21.1577 16.5778 20.2203 16.6262 20.2737 16.2587C20.3304 15.8879 21.9649 15.2882 21.4646 14.7885C20.7406 14.0663 17.1246 13.6375 14.8829 13.3732C14.6093 13.3345 14.3525 13.4119 14.3525 13.6924C14.2991 15.7234 14.1323 17.9706 14.4925 19.9211C14.5326 20.0533 14.6827 20.2177 14.8228 20.2435C18.7825 20.8561 22.959 20.5111 26.0581 18.3188C27.4991 17.1936 27.9661 15.6171 27.7193 13.8793C26.4717 9.0337 21.2444 6.1288 16.9545 4.2686C12.7746 2.4857 8.1544 1.5959 3.2006 1.828C2.4134 1.8667 1.1624 2.1375 1.1534 2.4083C1.1467 2.6792 2.5701 2.6018 2.3967 3.1047C2.2279 3.6109 0.779699 3.2982 0.350699 3.1821C-0.0783005 3.0661 -0.0362005 2.4471 0.0738995 2.0988C1.1534 0.238599 4.1647 0.0837995 5.5257 0.00649946C13.0515 -0.151501 20.3304 2.6018 25.1174 6.3997ZM11.557 13.5667C9.6489 13.5667 7.6273 13.773 5.856 14.4113C5.499 14.5467 5.052 14.9207 5.329 15.3785C6.0528 16.3102 7.077 17.084 8.0443 17.6225C9.0083 18.164 10.286 18.854 11.4469 19.012C11.7238 17.2774 11.7238 15.5623 11.6971 13.773C11.5837 13.7181 11.6971 13.6119 11.557 13.5667Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M37.717 3.2983C38.2507 3.2983 38.7177 3.4949 39.118 3.8044C39.6518 4.3074 39.7852 5.1198 39.5183 5.7421C38.5509 7.6797 36.2492 8.9564 34.6813 9.382C33.7473 9.6173 32.5463 9.6173 31.7023 9.2659C31.2853 9.5367 30.9385 10.3137 30.4281 9.9655C29.6941 9.4207 30.3313 8.5695 29.9077 7.9505C29.8177 7.7958 29.5974 7.7958 29.4907 7.6023C28.9102 6.3611 29.6275 5.1973 30.4281 4.2687C31.7691 2.7953 35.6154 1.2059 37.717 3.2983ZM33.2802 4.2687C32.5464 4.3847 31.689 4.6943 31.2354 5.1972C30.7784 5.7421 30.4848 6.245 30.7617 6.748C31.7324 6.0516 32.1994 5.1198 33.2802 4.5008C33.2802 4.4234 33.447 4.3461 33.2802 4.2687ZM37.7837 4.5008C35.8822 4.346 34.4478 5.742 33.08 7.096C33.0133 7.2895 32.5129 7.5248 32.8798 7.7957C34.7146 7.9504 36.4492 7.4442 37.7836 6.2449C38.1839 5.8967 38.4508 5.4711 38.2506 4.9649C38.1839 4.7715 37.9838 4.6169 37.7837 4.5008Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M34.2143 20.5112C34.281 17.8094 34.1476 15.4269 33.8807 12.8702C33.8473 12.2125 33.7473 11.5903 33.0801 11.2421C32.8466 11.0873 32.513 11.3195 32.4129 11.5903C31.8692 13.3345 31.8959 15.4011 31.8125 17.3032C31.8692 18.5058 31.6457 19.8405 32.2862 20.8335C32.4463 21.0721 33.0467 21.1495 33.4804 21.0721C33.7473 20.9367 34.1809 20.8561 34.2143 20.5112Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M45.3561 12.7929C45.6564 12.7542 45.9566 12.2513 45.9566 11.8643C45.8898 11.5903 45.6564 11.5129 45.4228 11.4356C42.1537 10.8552 38.2507 10.6618 35.6821 12.8702C35.1817 13.4119 35.1817 14.2823 35.4152 14.9207C35.5153 15.2238 36.0157 15.4817 36.416 15.5623C38.4175 15.7493 40.5191 15.7493 42.3538 16.2071C43.021 16.4456 44.0218 16.6326 44.0885 17.4902C44.0885 17.7288 43.7549 17.9706 43.5214 18.0512L43.5151 18.0529C41.3484 18.6425 38.9158 19.3044 36.7496 18.3736C36.4493 18.2285 36.6161 17.7836 36.883 17.6224C38.651 16.7906 40.0854 19.0668 41.9869 17.9964C42.2871 17.8062 42.8876 17.8384 42.7541 17.3032C41.0528 16.3102 38.5843 15.8846 36.5828 16.6616C36.1157 16.9164 35.7822 17.4516 35.7822 18.1286C35.8489 18.9861 36.6161 19.6793 37.4167 20.1081C39.485 21.2591 42.6207 21.3139 44.6556 20.0533C45.4896 19.4923 45.8232 18.4961 45.7564 17.6739C45.6897 16.6326 45.2894 15.3785 44.1552 15.1109C42.1203 14.5983 39.8853 14.5209 37.7169 14.1985C37.5502 14.1179 37.1832 14.0631 37.2499 13.7729C39.7519 12.9863 42.7208 13.2184 45.3561 12.7929Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M54.363 19.8695C55.9642 17.861 55.2303 14.5757 54.363 12.4833C53.7959 11.4355 53.2622 10.1975 52.0279 9.6559C51.5609 9.9267 51.7944 10.6231 51.8277 11.0874C52.2947 12.7155 52.7951 14.3307 53.0287 16.0717C53.0287 16.5134 53.2955 17.2484 52.7618 17.4902C52.0612 16.7423 51.5942 15.8041 51.0271 14.9755C50.2266 13.8793 49.526 12.3673 48.1916 11.8643C47.2909 12.5607 47.1241 13.6924 46.7906 14.6531C46.5904 16.552 45.9232 18.5315 46.5904 20.4305C46.7238 20.9077 47.2242 21.1495 47.6912 21.0721C48.9922 19.6277 48.225 17.2226 48.6252 15.3785C48.7254 15.2947 48.7254 15.0819 48.8922 15.1593C50.0263 16.9808 50.7936 19.012 52.6283 20.4305C53.0954 20.7948 53.896 20.3499 54.363 19.8695Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M64.6042 13.1797C64.9044 13.025 64.7709 12.7154 64.6042 12.4834C64.3706 12.0965 63.9036 11.4356 63.57 11.3969C61.9355 11.2034 60.1008 11.3969 58.4328 11.7096C57.7656 11.8257 56.965 11.7096 56.3312 12.0191C56.1977 13.141 57.5321 13.141 58.0325 13.6924C57.5655 14.1985 57.7656 15.2947 56.8316 15.2947C56.5313 15.3785 56.3312 15.6945 56.3979 16.0717C56.9316 16.926 56.0309 17.703 55.8642 18.5057C55.5639 19.6277 55.9642 20.9496 56.8315 21.3397C59.2334 22.3101 61.5018 20.9367 63.2365 19.602C63.4366 19.1764 63.1697 18.7992 62.9695 18.451C61.9355 18.2382 60.8013 18.5316 59.8005 18.6896C59.2334 18.7734 58.733 19.0668 58.266 18.7734C58.5329 18.106 58.4328 17.2774 59.1333 16.8454C59.6337 16.5811 61.4017 16.8454 62.5026 16.7133C62.9029 16.6907 63.0362 15.8008 62.9362 15.5623C62.3358 14.7627 61.0014 15.2141 60.1341 15.1593C59.9673 15.1754 59.8338 15.0561 59.7338 14.9755C59.567 14.4951 59.9006 13.9599 60.2342 13.6375C61.7019 13.4892 63.3699 13.7181 64.6042 13.1797Z" fill="currentColor"/>
        </g>
    </svg>

</template>
