<template>

    <svg class="ef-logo"
         width="44"
         height="25"
         viewBox="0 0 44 25"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M32.3909 8.11316C33.5241 12.6463 31.1978 16.9417 27.9766 19.7446C26.1875 21.3549 23.9806 22.4298 21.6543 23.0263L22.0121 21.416C25.9492 20.3423 29.5281 17.0618 30.6018 13.0646C31.3772 10.1417 30.6018 5.42916 25.5915 3.8783L25.9492 2.14801C29.1098 2.86343 31.7343 5.1303 32.3909 8.11316ZM22.7869 0.000576639L22.548 1.25315C19.3269 1.84972 16.4635 3.40058 14.1372 5.78629H11.9897C14.376 2.98286 17.2989 1.01429 20.58 0H22.7869V0.000576639Z" fill="currentColor"/>
        </g>
        <g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1334 0.000610352C9.54421 3.58005 2.0282 17.1806 12.2882 24.9349H8.1722C3.4602 20.3418 2.50534 8.17262 14.0179 0.000610352H18.1334Z" fill="currentColor"/>
        </g>
        <g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5275 0.000610352C7.51719 3.87833 4.29604 9.30576 3.93775 15.5098C3.81832 18.9704 4.83203 22.2509 6.97947 24.9349H3.28118C1.96918 22.7869 1.43203 20.2824 1.25317 18.7915L3.69889 6.92062C5.01147 4.35548 6.80061 2.08862 9.00804 0.00119139H12.5275V0.000610352ZM7.69604 0.000610352C6.38405 1.31261 5.25033 2.74462 4.23604 4.23605L5.1309 0.000610352H7.69604ZM22.0121 3.81776C20.4012 4.17547 18.9703 4.83204 17.5978 5.78633H15.3909C17.1206 4.17547 19.8052 2.68461 22.3703 2.14746L22.0121 3.81776ZM21.5949 5.78633H19.3281C20.1035 5.36861 20.9389 4.9509 21.8332 4.77204L21.5949 5.78633Z" fill="currentColor"/>
        </g>
        <g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0357 9.36515H11.5129C10.618 11.1549 10.022 13.1234 10.0815 15.2114H9.84259L9.06716 19.0892H11.0357C12.3483 21.6537 14.6152 23.3835 17.7763 24.0395L17.5969 24.9343H14.1963C10.4386 23.264 8.1123 19.3269 8.23115 15.2114C8.35116 11.8109 9.54373 8.82858 11.6912 6.20343L11.0357 9.36515Z" fill="currentColor"/>
        </g>
        <g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7629 9.36523C15.3309 11.3938 15.0921 12.2287 15.0326 13.899C15.0326 14.3173 15.0926 14.7938 15.2115 15.2115H13.6606C13.4218 13.1235 14.1972 11.0355 15.3309 9.36523H16.7629Z" fill="currentColor"/>
        </g>
        <g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.3865 9.36523C17.8957 10.6178 16.4637 12.7652 17.5374 15.2115H16.106C15.5094 13.2418 16.2854 11.0355 17.9551 9.36523H19.3865ZM14.2562 9.36523C13.2425 11.0955 12.646 12.9447 12.8248 15.2115H10.9163C10.8562 13.1235 11.4528 11.0955 12.4071 9.36523H14.2562Z" fill="currentColor"/>
        </g>
        <g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4463 15.212H18.6115C17.18 13.4823 18.432 10.7972 20.58 9.60459L19.4463 15.212ZM18.432 20.58C17.4777 20.2812 16.5229 19.8046 15.748 19.0892H18.7303L18.432 20.58ZM14.496 19.0892C15.4503 20.2812 16.8223 21.0566 18.3132 21.4749L17.9555 23.1452C15.5697 22.7269 13.3029 21.296 12.1097 19.0898H14.496V19.0892ZM2.26686 24.9343H0L0.776006 21.176C1.13372 22.488 1.61086 23.7412 2.26686 24.9343ZM42.8303 3.87772C42.4715 2.62457 41.9349 1.19315 41.2195 0H43.6058L42.8303 3.87772ZM35.2543 0.000576639C36.9246 1.67086 37.8789 3.63944 38.4155 5.78687H35.6715C34.7766 3.34116 33.0469 1.25373 30.8395 0.000576639H35.2543ZM40.2052 0.000576639C41.1001 1.19372 42.1143 4.05658 42.2932 5.78687H39.3703C38.8932 3.69887 37.8795 1.73087 36.4481 0.000576639H40.2052ZM28.6926 0.000576639C31.3766 0.954862 33.7035 3.1623 34.6578 5.78687H33.584L33.2263 7.69602C32.4509 4.77316 29.7669 2.02916 26.128 1.31315L26.3663 0.00115768H28.6926V0.000576639ZM29.8858 9.72344C30.4818 13.72 27.9172 17.2989 24.7555 19.2675C23.98 19.744 23.0852 20.1623 22.1903 20.4012L22.4886 18.9092C25.4115 17.896 28.2149 15.3303 28.5732 12.1692C28.8715 9.84172 27.7978 7.39716 25.1132 6.32286L25.4115 4.77201C27.7377 5.42858 29.5275 7.39716 29.8858 9.72344Z" fill="currentColor"/>
        </g>
        <g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M27.6783 10.8564C27.9177 13.7793 25.8886 16.6427 22.7274 17.8959L22.9663 16.7027C24.9349 15.7484 26.6051 13.7799 26.4857 11.3936C26.3663 10.2599 25.7097 9.12671 24.636 8.53014L24.9343 7.21814C26.4257 7.81471 27.5588 9.24614 27.6783 10.8564Z" fill="currentColor"/>
        </g>
        <g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M23.205 15.5091L24.4575 9.48456C27.0827 11.5131 24.577 14.9131 23.205 15.5091ZM40.0861 16.1663H38.2364C38.9524 14.3754 39.4895 12.408 39.6678 10.3794H41.2787L40.0861 16.1663ZM35.4335 10.3794C35.3735 12.408 34.7175 14.3754 33.8227 16.1663H31.9735C32.9278 14.4366 33.5838 12.4674 33.5838 10.3794H35.4335ZM38.7735 10.3794C38.6547 12.408 38.0575 14.3754 37.2827 16.1663H34.8364C35.6718 14.3754 36.2084 12.408 36.2678 10.3794H38.7735ZM30.5421 20.6994C28.693 22.6086 26.4261 24.0394 24.0998 24.9343H21.2964L21.4752 23.98C25.4118 23.0851 28.8124 20.9389 31.1981 17.4777L30.5421 20.6994ZM26.2472 24.9343C27.6787 24.2189 28.9913 23.264 30.2432 22.1903L29.7067 24.9343H26.2472Z" fill="currentColor"/>
        </g>
    </svg>

</template>
