<template>

    <svg class="lvmh-logo"
         width="64"
         height="17"
         viewBox="0 0 64 17"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <g>
            <path d="M26.487 0.300011V0.732011C25.953 0.828011 25.711 1.02001 25.42 1.26001C25.032 1.64501 24.643 2.22201 24.352 2.99001L18.87 16.45H18.385L12.563 2.84601C12.271 2.12501 12.029 1.74001 11.932 1.54801C11.7344 1.30481 11.4859 1.10786 11.204 0.971011C10.913 0.827011 10.719 0.731011 10.234 0.731011V0.299011H16.25V0.732011C15.522 0.780011 15.28 0.924011 15.085 1.11601C14.891 1.30801 14.745 1.50101 14.745 1.78901C14.745 2.17401 14.94 2.79901 15.28 3.61601L19.258 12.796L22.994 3.71201C23.334 2.79801 23.528 2.17401 23.528 1.83701C23.528 1.59701 23.431 1.40501 23.188 1.21201C22.945 1.02001 22.508 0.876011 21.975 0.780011C21.926 0.780011 21.878 0.780011 21.781 0.732011V0.299011H26.487V0.300011Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M12.861 11.713L13.244 11.808L11.904 15.851H0.097V15.423H0.67C1.34 15.423 1.674 15.233 1.961 14.805C2.105 14.567 2.201 13.996 2.201 13.14V3.00999C2.201 2.05899 2.105 1.43999 1.866 1.20299C1.578 0.869988 1.147 0.679988 0.525 0.679988H0V0.299988H6.837V0.679988C6.024 0.679988 5.498 0.726988 5.164 0.916988C4.829 1.05999 4.638 1.29699 4.494 1.53599C4.351 1.77299 4.303 2.34399 4.303 3.24799V13.235C4.303 13.853 4.351 14.329 4.494 14.567C4.59 14.71 4.734 14.852 4.924 14.947C5.116 15.042 5.881 15.042 6.884 15.042H7.841C9.036 15.042 9.849 14.947 10.327 14.757C10.805 14.567 11.236 14.281 11.666 13.806C12.096 13.33 12.479 12.712 12.861 11.713Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M30.44 2.91599L36.328 15.85L36.327 15.851H36.71L42.695 2.77199V13.189C42.695 14.044 42.646 14.615 42.455 14.854C42.215 15.234 41.737 15.424 41.067 15.424H40.588V15.852H46.906V15.424H46.476C45.853 15.424 45.471 15.281 45.183 14.949C44.944 14.711 44.848 14.139 44.848 13.189V2.96299C44.848 2.10799 44.944 1.53699 45.135 1.29799C45.375 0.917988 45.758 0.727988 46.428 0.727988H46.954V0.299988H42.742L37.19 12.474L31.59 0.299988H27.521V0.679988C28.143 0.679988 28.526 0.774988 28.813 0.917988C29.101 1.10799 29.292 1.24999 29.388 1.48799C29.531 1.77299 29.628 2.29699 29.628 2.96199V13.189C29.628 14.044 29.579 14.615 29.388 14.854C29.148 15.234 28.67 15.424 28 15.424H27.089V15.852H32.594V15.424H32.02C31.397 15.424 31.062 15.281 30.775 14.949C30.536 14.711 30.44 14.139 30.44 13.189V2.91599Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M59.515 7.38999H52.31L52.358 3.08899C52.358 2.33299 52.405 1.81299 52.501 1.57599C52.596 1.29299 52.739 1.15099 52.978 1.00899C53.265 0.819988 53.503 0.725988 53.885 0.725988H54.458V0.299988H48.158V0.676988H48.683C49.065 0.676988 49.304 0.770988 49.638 0.960988C49.876 1.10299 50.019 1.24399 50.115 1.43299C50.21 1.66999 50.258 2.18999 50.258 2.99299V13.062C50.258 14.007 50.163 14.575 49.971 14.811C49.638 15.189 49.351 15.378 48.731 15.378H48.207V15.804H54.457V15.378H53.885C53.503 15.378 53.264 15.284 52.93 15.095C52.692 14.952 52.548 14.811 52.453 14.622C52.358 14.338 52.31 13.866 52.31 13.062V8.23999H59.563V13.11C59.563 14.056 59.467 14.623 59.277 14.859C58.99 15.237 58.608 15.426 57.988 15.426H57.463V15.852H63.81V15.426H63.285C62.951 15.426 62.665 15.332 62.331 15.143C62.093 15.001 61.949 14.86 61.853 14.67C61.758 14.387 61.71 13.914 61.71 13.11V3.08899C61.71 2.33299 61.758 1.81299 61.853 1.57599C61.901 1.29299 62.092 1.15099 62.331 1.00899C62.617 0.819988 62.856 0.725988 63.237 0.725988H63.762V0.299988H57.416V0.676988H57.941C58.322 0.676988 58.561 0.770988 58.895 0.960988C59.134 1.10299 59.277 1.24399 59.372 1.43299C59.468 1.66999 59.515 2.18999 59.515 2.99299V7.38999Z" fill="currentColor"/>
        </g>
    </svg>

</template>
