<template>

    <svg class="epson-logo"
         width="83"
         height="20"
         viewBox="0 0 83 20"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M52.5048 10C52.5048 13.3445 53.8825 16.6356 57.455 16.6356C61.0269 16.6356 62.4052 13.3445 62.4052 10C62.4052 6.65553 61.0269 3.36352 57.455 3.36352C53.8825 3.36352 52.5048 6.65553 52.5048 10ZM48.5833 10C48.5833 4.21832 51.8305 0 57.455 0C63.0795 0 66.3267 4.21832 66.3267 10C66.3267 15.7814 63.0795 20 57.455 20C51.8305 20 48.5833 15.7814 48.5833 10Z" fill="currentColor"/>
        </g>
        <g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.6997 19.4391V13.7755H23.2529C28.0532 13.7755 31.2787 11.4125 31.2787 7.16807C31.2787 2.92338 28.0532 0.560303 23.2529 0.560303H15.7782V19.4391H19.6997ZM19.6997 10.412H23.2529C25.7178 10.412 27.3572 9.33774 27.3572 7.16807C27.3572 4.99811 25.7178 3.92382 23.2529 3.92382L19.6997 3.92441V10.412Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M67.8092 0.560303H71.8374L76.8358 8.94682C77.4756 10.0138 78.8501 12.8536 79.3033 13.7981C79.2336 12.1577 79.1575 10.1081 79.1575 8.0173V0.560303H83V19.4391H78.9745L73.9728 11.0529C73.3342 9.98624 71.9592 7.14609 71.5059 6.20191C71.5762 7.84206 71.6523 9.70111 71.6523 11.7914V19.4391H67.8092V0.560303Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M0 0.560303H14.098V3.92499H3.92243V8.11166H13.4117V11.3934H3.92243V16.0736H14.098V19.4391H0V0.560303Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M35.7317 13.1924C35.7317 15.7295 37.8405 16.7177 40.0831 16.7177C41.5504 16.7177 43.9265 16.2904 43.9265 14.3411C43.9265 12.5702 41.7704 12.0361 39.3822 11.4444L39.3818 11.4443C38.997 11.349 38.6062 11.2521 38.2174 11.1484C35.2776 10.371 32.4505 9.26564 32.4505 5.76791C32.4505 1.86989 36.1329 0 39.5754 0C43.5514 0 47.2062 1.73626 47.2062 6.143H43.2849C43.1511 3.84615 41.3908 3.25832 39.3895 3.25832C38.0534 3.25832 36.3718 3.8189 36.3718 5.42183C36.3718 6.87186 37.3076 7.11251 41.9356 8.30265L41.9356 8.30266L42.1079 8.34696C42.1734 8.36337 42.2457 8.38088 42.3238 8.39983L42.324 8.39987C43.8975 8.78127 47.848 9.73885 47.848 13.8602C47.848 17.3585 45.1006 19.976 39.9215 19.976C35.706 19.976 31.7569 17.893 31.8102 13.1924H35.7317Z" fill="currentColor"/>
        </g>
    </svg>

</template>
