<template>

    <svg class="kaspersky-logo"
         width="115"
         height="23"
         viewBox="0 0 115 23"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <g>
            <path d="M96.8196 4.77142H100.18L95.5068 10.9904L100.338 17.6918H96.9246L92.8025 11.8484V10.1596L96.8196 4.77142Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M91.3583 17.6918H88.6015V0H91.3583V17.6918Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M81.0816 9.83773L82.9194 10.0254C85.2825 10.2667 86.7528 11.4193 86.7528 13.6979C86.7528 16.2711 84.6523 18.0135 81.4229 18.0135C77.9855 18.0135 75.9805 16.1228 75.6947 13.6861H78.5636C78.8604 14.7521 79.7062 15.5473 81.4229 15.5473C83.2871 15.5473 83.996 14.8237 83.996 13.8318C83.996 12.7327 83.2345 12.4915 82.1056 12.3843L80.2413 12.1967C77.3795 11.9018 76.1192 10.5883 76.1192 8.55108C76.1192 6.11174 78.1934 4.44975 81.2653 4.44975C84.311 4.44975 86.2277 6.00453 86.3327 8.52424H83.6809C83.6021 7.53243 82.6831 6.83551 81.2391 6.83551C79.8475 6.83551 78.8761 7.42522 78.8761 8.44383C78.8761 9.30164 79.6374 9.70373 81.0816 9.83773Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M70.0281 6.27275H70.422C71.1445 5.18392 71.9548 4.44975 73.8615 4.44975H74.8716V7.29118H73.1264C71.0715 7.29118 70.0281 8.6315 70.0281 10.9368V17.6918H67.2713V4.77142H70.0281V6.27275Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M59.2326 15.467C60.699 15.467 61.7324 14.7002 62.1649 13.6861H64.9007C64.2516 16.171 62.1625 18.0135 59.2326 18.0135C56.0295 18.0135 52.9574 15.7886 52.9574 11.2317C52.9574 6.67468 56.0295 4.44975 59.2326 4.44975C62.6722 4.44975 65.0877 6.8623 65.0877 10.5883V11.9288H57.421V9.89135H62.3834C62.2783 8.1222 61.1756 6.94272 59.2588 6.94272C57.1059 6.94272 55.7144 8.47066 55.7144 11.2317C55.7144 14.1267 57.211 15.467 59.2326 15.467Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M45.5365 17.6918C48.8446 17.6918 51.4876 15.601 51.4876 11.1783C51.4876 6.70147 48.9431 4.44975 45.8974 4.44975C44.4795 4.44975 43.1209 5.01289 42.2019 6.27275H41.8081V4.77142H39.0512V22.2222H41.8081V11.1246C41.8081 8.36361 43.2718 7.07676 45.3198 7.07676C47.4465 7.07676 48.7044 8.44404 48.7044 11.1783C48.7044 13.9392 47.3743 15.0381 45.3789 15.0381H43.5147V17.6918H45.5365Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M31.5312 9.83773L33.3692 10.0254C35.7322 10.2667 37.2025 11.4193 37.2025 13.6979C37.2025 16.2711 35.1021 18.0135 31.8726 18.0135C28.4353 18.0135 26.4302 16.1228 26.1444 13.6861H29.0133C29.3101 14.7521 30.156 15.5473 31.8726 15.5473C33.7367 15.5473 34.4457 14.8237 34.4457 13.8318C34.4457 12.7327 33.6843 12.4915 32.5552 12.3843L30.6911 12.1967C27.8291 11.9018 26.5688 10.5883 26.5688 8.55108C26.5688 6.11174 28.6431 4.44975 31.7151 4.44975C34.7608 4.44975 36.6774 6.00453 36.7824 8.52424H34.1305C34.0518 7.53243 33.1329 6.83551 31.6887 6.83551C30.2973 6.83551 29.3257 7.42522 29.3257 8.44383C29.3257 9.30164 30.0872 9.70373 31.5312 9.83773Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M13.1654 8.52424C13.4279 6.05832 15.6859 4.44975 18.7053 4.44975C21.9873 4.44975 24.1141 5.87049 24.1141 9.06039V17.6918H21.3572V16.5928H20.9634C19.9394 17.3704 18.5478 18.0135 16.9724 18.0135C14.163 18.0135 12.4565 16.2711 12.4565 13.9659C12.4565 11.4999 14.2156 9.89135 17.3663 9.89135H19.6505V11.9288H17.445C16.0272 11.9288 15.2921 12.6523 15.2921 13.8053C15.2921 15.0113 16.2373 15.6814 17.6026 15.6814C18.9154 15.6814 20.2282 15.0651 21.3572 13.7514V9.32864C21.3572 8.01533 20.7008 6.94292 18.7053 6.94292C17.1562 6.94292 16.3423 7.61307 16.1322 8.52424H13.1654Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M8.21809 4.77142H11.5788L6.90532 10.9904L11.7364 17.6918H8.3231L4.20095 11.8484V10.1596L8.21809 4.77142Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M2.75689 17.6918H0V0H2.75689V17.6918Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M111.24 4.77142L108.982 9.89135C108.431 11.1246 107.906 12.3306 107.748 13.135H107.354C107.196 12.3306 106.645 11.1513 106.094 9.91836L103.836 4.77142H100.79L106.094 16.3784L103.468 22.2222H106.409L114.286 4.77142H111.24Z" fill="currentColor"/>
        </g>
    </svg>

</template>
