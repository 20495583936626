<template>

    <svg class="microsoft-logo"
         width="90"
         height="17"
         viewBox="0 0 90 17"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">

        <g>
            <path d="M69.864 14.825C68.877 14.825 68.099 14.505 67.551 13.874C66.999 13.24 66.72 12.336 66.72 11.188C66.72 10.003 67 9.076 67.551 8.431C68.1 7.791 68.871 7.465 69.842 7.465C70.785 7.465 71.536 7.775 72.073 8.389C72.613 9.005 72.888 9.925 72.888 11.123C72.888 12.336 72.63 13.268 72.121 13.892C71.616 14.51 70.857 14.825 69.864 14.825ZM69.985 5.29C68.102 5.29 66.607 5.83 65.541 6.894C64.475 7.959 63.935 9.432 63.935 11.274C63.935 13.023 64.462 14.43 65.502 15.454C66.542 16.48 67.958 17 69.71 17C71.535 17 73.001 16.452 74.067 15.373C75.133 14.295 75.673 12.835 75.673 11.037C75.673 9.26 75.166 7.843 74.166 6.825C73.166 5.806 71.758 5.29 69.985 5.29Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M48.085 14.825C47.098 14.825 46.32 14.505 45.771 13.874C45.219 13.24 44.941 12.336 44.941 11.188C44.941 10.003 45.22 9.076 45.771 8.431C46.321 7.791 47.091 7.465 48.063 7.465C49.005 7.465 49.756 7.775 50.293 8.389C50.834 9.005 51.108 9.925 51.108 11.123C51.108 12.336 50.85 13.268 50.342 13.892C49.837 14.51 49.078 14.825 48.085 14.825ZM48.206 5.29C46.323 5.29 44.827 5.83 43.761 6.894C42.696 7.959 42.155 9.432 42.155 11.274C42.155 13.024 42.683 14.43 43.723 15.454C44.763 16.48 46.179 17 47.931 17C49.755 17 51.221 16.452 52.288 15.373C53.353 14.295 53.893 12.835 53.893 11.037C53.893 9.26 53.386 7.843 52.387 6.825C51.386 5.806 49.979 5.29 48.206 5.29Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M90 5.559V7.702H87.28V12.976C87.28 13.652 87.404 14.136 87.648 14.412C87.89 14.685 88.279 14.825 88.805 14.825C88.9875 14.8183 89.1683 14.788 89.343 14.735C89.5363 14.6863 89.7209 14.6076 89.89 14.502L90 14.43V16.594L89.964 16.614C89.803 16.705 89.527 16.79 89.123 16.875C88.727 16.958 88.339 17 87.969 17C85.723 17 84.583 15.802 84.583 13.44V7.702H80.551V16.73H77.831V7.702H75.937V5.559H77.831V4.013C77.831 3.236 78.006 2.534 78.352 1.926C78.6954 1.32073 79.2057 0.82708 79.822 0.504C80.451 0.169 81.172 0 81.966 0C82.595 0 83.103 0.067 83.476 0.197L83.523 0.214V2.471L83.423 2.428C83.023 2.253 82.625 2.164 82.241 2.164C81.707 2.164 81.288 2.333 80.995 2.663C80.7 2.997 80.55 3.498 80.55 4.153V5.56H84.583V3.034L84.633 3.019L87.188 2.255L87.28 2.227V5.559H90Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M56.4 6.242C57.21 5.61 58.27 5.29 59.55 5.29C59.9998 5.29551 60.4482 5.34069 60.89 5.425C61.389 5.516 61.8 5.628 62.112 5.758L62.154 5.776V8.206L62.043 8.132C61.705 7.906 61.3 7.723 60.84 7.586C60.379 7.448 59.916 7.378 59.463 7.378C58.975 7.378 58.585 7.486 58.305 7.698C58.028 7.909 57.893 8.169 57.893 8.495C57.893 8.861 58.002 9.15 58.218 9.355C58.441 9.567 58.984 9.843 59.831 10.175C60.894 10.595 61.655 11.068 62.094 11.585C62.536 12.105 62.76 12.752 62.76 13.505C62.76 14.549 62.344 15.402 61.522 16.042C60.706 16.678 59.586 17 58.196 17C57.746 17 57.227 16.941 56.656 16.826C56.084 16.711 55.6 16.564 55.214 16.391L55.174 16.371V13.81L55.286 13.889C55.7525 14.2166 56.266 14.4715 56.809 14.645C57.357 14.821 57.857 14.911 58.295 14.911C59.477 14.911 60.052 14.545 60.052 13.795C60.052 13.532 59.995 13.318 59.883 13.162C59.768 13.002 59.565 12.838 59.278 12.676C58.984 12.509 58.523 12.299 57.904 12.049C57.167 11.739 56.611 11.435 56.251 11.144C55.888 10.852 55.615 10.503 55.438 10.106C55.263 9.711 55.174 9.245 55.174 8.721C55.174 7.712 55.586 6.878 56.4 6.242Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M38.027 5.559V7.494H38.056C38.307 6.865 38.635 6.362 39.089 5.995C39.5953 5.58098 40.231 5.358 40.885 5.365C41.333 5.365 41.687 5.417 41.937 5.521L41.981 5.539V8.189L41.868 8.109C41.744 8.021 41.532 7.938 41.242 7.861C40.947 7.785 40.676 7.745 40.434 7.745C39.72 7.745 39.135 8.047 38.697 8.643C38.252 9.246 38.027 10.044 38.027 11.016V16.73H35.341V5.559H38.027Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M27.265 6.058C28.2 5.548 29.299 5.29 30.531 5.29C31.0353 5.29227 31.5381 5.34586 32.032 5.448C32.551 5.553 32.969 5.692 33.275 5.86L33.311 5.88V8.415L33.199 8.335C32.385 7.758 31.525 7.465 30.642 7.465C29.61 7.465 28.762 7.808 28.123 8.482C27.481 9.159 27.156 10.077 27.156 11.209C27.156 12.335 27.468 13.229 28.083 13.865C28.697 14.502 29.543 14.825 30.597 14.825C30.977 14.825 31.41 14.743 31.884 14.581C32.3525 14.4227 32.7955 14.197 33.199 13.911L33.311 13.831V16.238L33.276 16.258C32.402 16.75 31.312 17 30.036 17C28.942 17 27.954 16.765 27.1 16.302C26.2526 15.8462 25.5547 15.1556 25.09 14.313C24.612 13.46 24.37 12.491 24.37 11.435C24.37 10.231 24.62 9.15 25.11 8.225C25.5966 7.30422 26.3469 6.54966 27.265 6.058Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M19.835 5.559H22.521V16.73H19.835V5.559Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M20.061 1.239C20.377 0.947 20.763 0.799 21.205 0.799C21.67 0.799 22.063 0.95 22.373 1.251C22.683 1.55 22.84 1.915 22.84 2.335C22.84 2.762 22.678 3.124 22.36 3.409C22.043 3.693 21.655 3.837 21.205 3.837C20.755 3.837 20.368 3.693 20.055 3.409C19.741 3.125 19.581 2.762 19.581 2.335C19.581 1.899 19.743 1.531 20.061 1.239Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M14.503 16.73V4.735H14.415L9.55 16.73H7.606L2.665 4.734H2.576V16.73H0V1.142H3.948L8.628 12.72L13.451 1.142H17.244V16.73H14.503Z" fill="currentColor"/>
        </g>

    </svg>

</template>
