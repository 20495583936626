<template>

    <svg class="porsche-logo"
         width="145"
         height="11"
         viewBox="0 0 145 11"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.0198059 0.716797H16.9655C17.5677 0.716797 18.6859 0.80639 18.6859 2.23987V6.8091C18.6859 7.52585 18.2558 8.51137 17.3096 8.51137H1.91222V10.8408H0.0198059V0.716797ZM15.9333 6.36114C16.4494 6.36114 16.7075 6.09236 16.7075 5.37562V3.31499C16.7075 2.95662 16.4494 2.59824 15.7613 2.59824H1.91222V6.36114H15.9333Z" fill="currentColor"/>
        </g>
        <g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.2127 0.716797H38.8144C39.3305 0.716797 39.7606 1.16476 39.7606 1.70232V9.76567C39.7606 10.3032 39.3305 10.7512 38.8144 10.7512H22.2127C21.6966 10.7512 21.2665 10.3032 21.2665 9.76567V1.70232C21.2665 1.16476 21.6966 0.716797 22.2127 0.716797ZM37.18 8.86974C37.5241 8.86974 37.8681 8.60096 37.8681 8.24259V3.1358C37.8681 2.77743 37.6101 2.50865 37.18 2.50865H23.9331C23.589 2.50865 23.2449 2.77743 23.2449 3.1358V8.24259C23.2449 8.60096 23.503 8.86974 23.9331 8.86974H37.18Z" fill="currentColor"/>
        </g>
        <g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M60.9212 6.8987C60.9212 8.153 60.2331 8.42178 59.975 8.51137L61.0933 10.8408H58.8568L57.8246 8.51137H44.1475V10.9304H42.2551V0.806396H59.4589C60.4051 0.806396 60.9212 1.52314 60.9212 2.68784V6.8987ZM58.2547 6.36115C58.8568 6.36115 59.1148 6.09237 59.1148 5.46522V3.40459C59.1148 2.86703 58.8568 2.59825 58.2547 2.59825H44.2336V6.36115H58.2547Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M81.7378 0.627197V2.68783H66.8565C66.2544 2.68783 66.1684 3.13579 66.1684 3.40457C66.1684 3.67335 66.1684 4.03172 66.1684 4.03172C66.1684 4.03172 66.0824 4.74846 66.7705 4.74846C67.4587 4.74846 80.0174 4.74846 80.2755 4.74846C81.5658 4.74846 81.8238 5.82358 81.8238 6.36113C81.8238 6.89869 81.8238 9.04892 81.8238 9.04892C81.8238 10.3928 80.8776 10.7512 80.1895 10.7512C79.5013 10.7512 64.276 10.7512 64.276 10.7512V8.78014H79.0712C79.7594 8.78014 79.9314 8.51136 79.9314 8.0634C79.9314 7.70503 79.9314 7.16747 79.9314 7.16747C79.9314 6.54032 78.9852 6.62991 78.5551 6.62991C78.211 6.62991 65.6523 6.62991 65.6523 6.62991C64.534 6.62991 64.276 5.73398 64.276 5.10684C64.276 4.39009 64.276 1.97109 64.276 1.97109C64.276 1.07516 65.1362 0.627197 65.9103 0.627197C66.8565 0.627197 81.7378 0.627197 81.7378 0.627197Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M102.726 0.627197V2.50865H88.0172C87.3291 2.50865 87.157 3.0462 87.157 3.49417C87.157 3.94213 87.157 7.9738 87.157 7.9738C87.157 8.69055 87.3291 8.86973 87.8452 8.86973C88.3613 8.86973 102.726 8.86973 102.726 8.86973V10.7512H86.8129C85.4366 10.7512 85.3506 9.76566 85.3506 8.95932C85.3506 8.0634 85.3506 1.97109 85.3506 1.97109C85.3506 0.627197 86.5549 0.627197 86.899 0.627197C87.243 0.627197 102.726 0.627197 102.726 0.627197Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M121.479 0.537598V4.74846H107.544V0.537598H105.651V10.8408H107.544V6.7195H121.479V10.8408H123.371V0.537598H121.479Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M144.962 2.59824V0.627197H126.296V10.8408H144.962V8.86973H128.188V6.62991H144.962V4.65887H128.188V2.59824H144.962Z" fill="currentColor"/>
        </g>
    </svg>

</template>
