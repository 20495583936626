<template>

    <svg class="hublot-logo"
         width="108"
         height="15"
         viewBox="0 0 108 15"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">

        <g>
            <path d="M88.113 11.898C85.498 11.898 83.447 9.871 83.447 7.284C83.447 4.696 85.498 2.671 88.113 2.671C90.733 2.671 92.78 4.697 92.78 7.284C92.78 9.87 90.733 11.898 88.113 11.898ZM88.113 0.00199986C83.983 0.00199986 80.747 3.202 80.747 7.286C80.747 11.371 83.983 14.568 88.113 14.568C92.247 14.568 95.483 11.371 95.483 7.286C95.483 3.201 92.247 0.00199986 88.113 0.00199986Z" fill="currentColor"/>
        </g>

        <g>
            <path d="M96.578 3.214V0.396H107.81V3.214H103.596V14.257H100.902V3.214H96.578Z" fill="currentColor"/>
        </g>

        <g>
            <path d="M70.633 14.254V0.396H73.325V11.45H79.911V14.254H70.633Z" fill="currentColor"/>
        </g>

        <g>
            <path d="M48.246 7.631V0.396L48.247 0.397H50.947V7.802C50.947 11.059 50.034 14.642 44.612 14.642C39.188 14.642 38.272 11.109 38.272 7.802V0.397H40.95V7.631C40.95 9.78 41.192 11.963 44.612 11.963C48.031 11.963 48.246 9.734 48.246 7.631Z" fill="currentColor"/>
        </g>

        <g>
            <path d="M31.67 5.558V0.396H34.344V14.259H31.669V8.394H24.744V14.259H22.05V0.394H24.745V5.558H31.67Z" fill="currentColor"/>
        </g>

        <g>
            <path d="M12.685 0.396H14.153C14.153 4.298 12.768 5.869 10.613 6.919V14.275H8.353V7.818L7.436 8.134C7.022 8.276 6.61 8.423 6.2 8.577V14.274H3.939V9.627C2.49 10.546 1.884 11.78 1.884 14.273H0.416C0.416 10.622 1.655 9.003 3.939 7.832V0.395H6.201V6.887C6.46429 6.79166 6.72829 6.69832 6.993 6.607C7.479 6.438 7.932 6.285 8.355 6.135V0.395H10.615V5.147C12.013 4.302 12.685 3.083 12.685 0.396Z" fill="currentColor"/>
        </g>

        <g>
            <path d="M61.883 11.726H57.21L57.208 8.244H61.898C63.288 8.244 63.891 8.789 63.891 10.036C63.89 10.967 63.511 11.726 61.883 11.726ZM61.466 2.936C62.965 2.936 63.426 3.484 63.426 4.396C63.426 5.309 63.028 5.96 61.523 5.96H57.207V2.937H61.467L61.466 2.936ZM65.026 6.99C65.86 6.335 66.333 5.378 66.333 4.225C66.333 3.073 65.87 0.397 61.598 0.397H54.531V14.257H62.048C65.071 14.257 66.81 12.717 66.81 10.04C66.81 8.733 66.19 7.691 65.026 6.99Z" fill="currentColor"/>
        </g>

    </svg>

</template>
