<template>

    <svg class="nike-logo"
         width="66"
         height="24"
         viewBox="0 0 66 24"
         xmlns="http://www.w3.org/2000/svg">
        <g>
            <path d="M8.454 1.064C4.16 6.16.042 12.48 0 17.204c-.016 1.776.546 3.328 1.893 4.504C3.83 23.4 5.967 23.996 8.093 24c3.105.004 6.19-1.262 8.605-2.238C20.765 20.119 65.722.393 65.722.393c.434-.22.352-.493-.19-.356-.22.055-48.943 13.397-48.943 13.397-.94.266-1.901.404-2.836.41-3.745.022-7.077-2.078-7.05-6.504.011-1.731.536-3.82 1.751-6.276" fill="currentColor" fill-rule="evenodd"/>
        </g>
    </svg>

</template>
