<template>

    <svg class="ikea-logo"
         width="80"
         height="28"
         viewBox="0 0 80 28"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H79.612V27.804H0V0ZM79.03 13.902C79.03 21.172 61.479 27.067 39.806 27.067C18.133 27.067 0.582001 21.172 0.582001 13.902C0.582001 6.632 18.132 0.737 39.806 0.737C61.48 0.737 79.03 6.632 79.03 13.902Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M30.642 9.285L28.218 12.625V10.365C28.218 9.726 28.315 9.137 28.412 9.039C28.509 8.94 28.461 8.842 28.267 8.842H21.77C21.576 8.842 21.527 8.94 21.624 9.039C21.673 9.137 21.818 9.726 21.818 10.365V17.881C21.818 18.519 21.721 19.109 21.624 19.207C21.527 19.305 21.576 19.404 21.77 19.404H28.315C28.509 19.404 28.558 19.305 28.461 19.207C28.412 19.109 28.267 18.519 28.267 17.881V15.375L30.352 18.618C30.448 18.715 30.303 19.256 30.303 19.305C30.303 19.354 30.303 19.404 30.352 19.404H38.4C38.4951 19.404 38.4499 19.3569 38.4028 19.3079L38.4 19.305L34.667 13.312C34.521 13.116 34.473 12.968 34.618 12.821L37.333 8.989C37.382 8.941 37.43 8.891 37.333 8.891H30.739C30.691 8.891 30.691 8.94 30.691 8.989C30.691 9.039 30.739 9.186 30.642 9.285Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M18.327 19.158C18.424 19.256 18.376 19.354 18.182 19.354H18.18H11.49C11.296 19.354 11.247 19.256 11.344 19.158C11.393 19.06 11.538 18.47 11.538 17.832V10.365C11.538 9.726 11.441 9.137 11.344 9.039C11.247 8.94 11.296 8.842 11.49 8.842H18.182C18.376 8.842 18.424 8.94 18.327 9.039C18.279 9.137 18.133 9.726 18.133 10.365V17.832C18.133 18.47 18.23 19.06 18.327 19.158Z" fill="currentColor"/>
        </g>
        <g>
            <path d="M70.69 19.305C70.69 19.305 66.812 10.021 66.764 9.874C66.715 9.726 66.667 9.432 66.764 9.235C66.861 8.989 66.764 8.94 66.618 8.94H57.6C57.455 8.94 57.358 8.99 57.455 9.235C57.5288 9.44161 57.5288 9.66739 57.455 9.874C57.406 10.021 53.964 19.305 53.964 19.305H59.539C59.685 19.305 59.685 19.256 59.636 19.158C59.588 19.06 59.588 18.863 59.636 18.716L59.927 17.733C60.024 17.439 60.17 17.389 60.412 17.389H62.836C63.079 17.389 63.224 17.439 63.321 17.733L63.612 18.716C63.661 18.863 63.661 19.06 63.612 19.158C63.564 19.256 63.612 19.305 63.709 19.305H70.691H70.69ZM60.848 15.277C60.752 15.277 60.752 15.179 60.8 15.13L61.382 13.705C61.43 13.656 61.43 13.607 61.479 13.607C61.527 13.607 61.527 13.657 61.576 13.705L62.158 15.13C62.206 15.179 62.158 15.277 62.109 15.277H60.849H60.848Z" fill="currentColor"/>
        </g>
        <g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M46.933 15.474C46.933 15.424 46.933 15.277 47.127 15.277H49.697C49.939 15.277 50.909 15.228 51.007 15.327C51.103 15.425 51.2 15.375 51.2 15.179V12.674C51.2 12.526 51.103 12.477 51.006 12.526C50.909 12.625 49.988 12.576 49.697 12.576H47.127C46.933 12.576 46.933 12.428 46.933 12.379V11.888C46.933 11.838 46.933 11.691 47.127 11.691H50.812C51.442 11.691 52.024 11.839 52.122 11.888C52.218 11.986 52.315 11.937 52.315 11.74V8.842H40.68C40.486 8.842 40.437 8.94 40.534 9.039C40.631 9.137 40.728 9.726 40.728 10.365V17.782C40.728 18.422 40.583 19.011 40.534 19.109C40.437 19.207 40.486 19.305 40.68 19.305H52.316V16.211C52.316 16.014 52.219 15.965 52.122 16.063C52.025 16.161 51.443 16.26 50.813 16.26H47.128C46.934 16.26 46.934 16.112 46.934 16.063V15.473L46.933 15.474Z" fill="currentColor"/>
        </g>
    </svg>

</template>
